.read-more-container {
  height: auto;
  min-height: 100vh;

  .glass-text-container {
    color: #faf8f8;
    text-align: left;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    span {
      font-size: 30px;
      margin-bottom: 40px;
    }

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 1.5;
    }
  }

  button {
    display: flex;
    align-items: center;
    gap: 10px;
    background: transparent;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    position: absolute;
    top: 0;
    cursor: pointer;
    transition: 0.5s;
    margin: 20px;

    &:hover {
      background: linear-gradient(135deg,
          rgba(255, 255, 255, 0.1),
          rgba(255, 255, 255, 0));
      transform: scale(1.1);
    }
  }

  .white-glass {
    display: flex;
    flex-wrap: wrap;
    background: linear-gradient(135deg,
        rgba(255, 255, 255, 0.1),
        rgba(255, 255, 255, 0));
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
    height: auto;
    max-width: 700px;
    position: relative;
    top: 100px;
    left: 180px;
    padding: 40px 20px;
    font-size: 20px;
    text-align: justify;
    text-justify: inter-word;
    word-break: break-all;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
  }
}


@media (max-width: 1600px) {
  .read-more-container {

    .white-glass {
      max-width: 500px;
      margin-bottom: 200px;
    }
  }
}

@media (max-width: 1300px) {
  .read-more-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .white-glass {
      left: 0;
    }
  }
}