main {
  font-family: "Roboto", sans-serif;
  min-height: 100vh;
  width: 100vw;
  background: linear-gradient(to bottom right, #4a4f55, #0c0d0e);
}

.lock {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 140px;
  right: 300px;
  overflow: visible;
  transition: top 0.5s ease-in-out;
  img {
    height: 300px;
  }
}

@media(max-width: 1600px) {
  .lock {
    right: 100px;
  }
}

@media (max-width: 1200px) {
  .lock {
    top: 310px;
    right: 0;
    left: 50%;
    transform: translateX(-50%);
    overflow: visible;
    z-index: 0;
    width: 300px;
  }
}

@media(max-width:600px) {
  .lock {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 310px;
    right: 0;
    left: 50%;
    transform: translateX(-50%);
    overflow: visible;
    z-index: 0;
    width: 300px;
    

    img {
      height: 200px;
      width: 300px;
    }
  }
}

