.animated {
  .lock-two {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 140px;
    right: 150px;
    overflow: visible;
  }

  .lock-two img {
    height: 300px;
    animation: moveClose 8s infinite;
  }
}

@keyframes moveClose {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(50px);
  }
}

.lock-two img:nth-child(2) {
  animation: moveCloseReverse 8s infinite;
}

@keyframes moveCloseReverse {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-50px);
  }
}

@media (max-width: 1300px) {
  .animated {
    .lock-two {
      display: none;
    }
  }
}