.about-us {
  min-height: 110vh;
  background-color: #e6e9ee;
  background-image: url("../../img/svg/about-us-bg-2.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.white-glass {
  display: flex;
  flex-wrap: wrap;
  background: linear-gradient(135deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  height: auto;
  max-width: 600px;
  position: relative;
  top: 100px;
  left: 180px;
  padding: 40px;
  font-size: 20px;
  text-align: justify;
  text-justify: inter-word;
  word-break: break-all;
  color: #3d4146;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;

  h3 {
    margin-bottom: 20px;
    font-size: 40px;
    color: rgb(50, 50, 50);
  }

  .glass-text {
    max-height: 350px;
    min-height: 250px;
    overflow-y: auto;
  }

  .read-more {
    color: rgb(50, 50, 50);
  }
}

.text {
  overflow: hidden;
}

.text p {
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 400;
}

.text p:first-child {
  font-size: 20px;
}

.glass-text::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

.glass-text::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  height: 15px;
  border-radius: 2px;
}

.glass-text::-webkit-scrollbar {
  width: 5px;
}

.glass-text::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.8);
  cursor: pointer;
}

.see-more {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  border: 1px solid black;
  cursor: pointer;
  transition: 0.5s;
  rotate: 90deg;
  right: 40px;
  bottom: 20px;
  position: absolute;

  &:hover {
    background-color: #3d4146;
    transform: scale(1.2);
  }

  svg {
    path {
      stroke: black;
      transition: 0.5s;
    }
  }

  &:hover {
    svg {
      path {
        stroke: rgb(255, 255, 255);
      }
    }
  }
}

@media (max-width: 950px) {
  .white-glass {
    left: 0;
    margin: 20px;
  }
}