.offer {
  min-height: 250vh;
  background-image: url("../../img/svg/offer-bgsvg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  .glass-text {
    height: 300px;
    margin-bottom: 50px;
    p {
      color: white;
      margin-top: 15px;
    }

    span {
      font-size: 40px;
      color: rgb(233, 233, 233)

    }
  }
}

@media (max-width: 1600px) {
  .offer {
    min-height: 300vh;
  }
}
