footer {
  background-color: #060a0f;
  font-family: "Roboto", sans-serif;
  color: white;
  width: 100vw;

  img {
    height: 120px;
    width: 120px;
  }
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;

  .contact {
    font-size: 16px;
    display: flex;
    gap: 50px;
    align-items: flex-start;
    justify-content: center;

    >div {
      display: flex;
      flex-direction: column;
      gap: 5px;
      font-weight: 300;
    }
  }
}

.lines {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;

  & :nth-child(1) {
    height: 5px;
    width: 20px;
    background-color: white;
  }

  & :nth-child(2) {
    height: 1px;
    width: 80px;
    background-color: white;
  }
}

@media (max-width: 600px) {
  .footer-container {
    flex-direction: column;
    padding: 20px;

    img {
      margin-bottom: 20px;
    }

    .contact {
      flex-direction: column;
      font-size: 12px;
      gap: 20px;
    }
  }

  footer {
    img {
      height: 90px;
      width: 90px;
    }
  }
}