@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');
* {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  scroll-behavior: smooth;
  font-family: 'Roboto', sans-serif;
}
