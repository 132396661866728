.card {
  min-height: 300px;
  width: auto;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(125, 119, 119, 0.8);
    z-index: 0;
  }

  .text-container {
    position: relative;
    z-index: 4;
    color: #faf8f8;
    padding: 20px 0;
    max-width: 450px;
    word-break: break-all;

    h4 {
      max-width: 450px;
      font-size: 28px;
      text-align: left;
      margin-bottom: 20px;
      word-break: normal;
    }

    p {
      font-size: 18px;
      margin-top: 20px;
      font-weight: 400;
    }
  }

  .card-inside {
    padding: 20px;
  }

  button {
    padding: 10px 15px;
    font-size: 15px;
    background-color: transparent;
    transition: ease-in-out 0.2s;
    border: none;
    position: relative;
    z-index: 1;
    border: 1px solid #faf8f8;
    color: #faf8f8;
    cursor: pointer;
    border-radius: 8px;

    &:hover {
      background-color: rgb(83, 72, 72);
    }
  }
}
