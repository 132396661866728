.landing-page {
  font-family: "Roboto", sans-serif;
  min-height: 100vh;
  width: 100vw;
  background-image: url("../../img/svg/main-bh.svg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px;
  color: #faf8f8;
}
.description {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 600px;
  top: 30%;
  left: 200px;
  position: absolute;
  z-index: 1;


  button {
    background: linear-gradient(to bottom right, #b92336, #e54048);
    padding: 10px 20px;
    color: #faf8f8;
    border: none;
    border-radius: 8px;
    margin-top: 30px;
    cursor: pointer;
    width: 100px;
    font-size: 15px;
  }
  span {
    color: #e54048;
  }
}
h1 {
  font-size: 70px;
}



@media (max-width: 600px) {
  .description {
    left: 0;
    margin: 30px;

    h1 {
      font-size: 40px;
    }
  }
}
