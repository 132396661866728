.navbar {
  display: flex;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  font-family: "Roboto", sans-serif;
  width: 100vw;
  z-index: 1000;
  color: #faf8f8;

  img {
    height: 100px;
    width: 100px;
    margin-right: 50px;
    margin-left: 30px;
    cursor: pointer;
  }

  .toggle {
    margin-right: 30px;
    display: flex;
    align-items: center;
  }
}
.nav-content {
  display: flex;
  align-items: center;
}

ul {
  display: flex;
  color: #faf8f8;
  gap: 50px;
  font-size: 20px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.menu-toggle {
  display: none;
  font-size: 30px;
  margin-left: 20px;
}

.menu {
  display: flex;
  justify-content: flex-end;
  margin-right: 40px;
}

.drop_down_menu {
  display: none;
  position: fixed;
  width: 100vw;
  top: 100px;
  background-color: rgba(48, 48, 48, 0.9);
  text-align: center;
  color: white;

  li {
    padding: 20px;
    &:hover {
      background-color: rgb(41, 41, 41);
    }
  }
}
a {
  text-decoration: none;
  color: #faf8f8;
}
.navbar.scrolled {
  background-color: rgba(48, 48, 48, 0.9);
  transition: background-color 0.3s ease-in-out;
}

@media (max-width: 800px) {
  .menu-toggle {
    display: block;
  }
  .toggle {
    margin-right: 10px;
  }
  .menu {
    display: none;
  }
  .drop_down_menu {
    display: block;
  }
  ul {
    flex-direction: column;
    background-color: #69788a;
  }
}
